import React, { Component, createRef } from 'react'

import PropTypes from 'prop-types'

import { Link } from 'gatsby'

import styled from 'styled-components'

import Modal from 'react-modal'

import Layout from '../../components/layout'

import { MainSectionWrapper } from './styles'

import { getImgSizeInfo } from '../../util'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
  grid-row: 3 / 5;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 89%;
  max-width: 717px;

  padding: 0;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 90%;

  overflow: hidden;

  background: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const ActionsContainer = styled.div`
  max-width: 717px;
  width: 100%;
  display: flex;
  justify-content: ${props => props.moreInfo || props.link ? 'space-between' : 'flex-end'};
  margin: 0 auto;

  & a {
    align-self: flex-end;
  }
`

const NavigatingCircles = styled.div`  
  display: flex;
  align-self: flex-end;

  margin: 10px 0;
  max-width: 717px;
`

const NavigatingCircle = styled.div`
  margin: 3px;
  
  width: 10px;
  height: 10px;

  border: 2px solid ${props => props.circleColor};
  border-radius: 5px;
  background: ${props => props.fillBackground ? props.circleColor : 'transparent'};

  cursor: pointer;
`

export class Gallery extends Component {
  imageRef = React.createRef()

  state = {
    currentImageIndex: 0,
    modalOpen: false,
  }

  componentDidMount = () => {
    this.setupAutoplay()
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount = () => {
    this.removeAutoplay()
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  setupAutoplay = () => {
    const {
      autoplay,
    } = this.props

    if (autoplay) {
      this.autoplay = setInterval(this.goToNextSlide, 5000)
    }
  }

  removeAutoplay = () => {
    const {
      autoplay,
    } = this.props

    if (autoplay) {
      clearInterval(this.autoplay)
    }
  }

  handleImageLoaded = () => {
    const imageDimensions = getImgSizeInfo(this.imageRef.current)
    const imageWidth = imageDimensions.width

    this.setState({
      imageWidth: `${imageWidth}px`,
    })
  }

  handleLinkClick = (e) => {
    if (this.props.moreInfo) {
      e.preventDefault()
      this.setState({ modalOpen: true })
    }
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        this.removeAutoplay()
        this.goToPreviousSlide()
        break
      case "ArrowRight":
        this.removeAutoplay()
        this.goToNextSlide()
        break
    }
  }

  onAfterOpenModal = () => {
    window.removeEventListener('keydown', this.handleKeyDown)
    this.removeAutoplay()
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: false })
    window.addEventListener('keydown', this.handleKeyDown)
    this.setupAutoplay()
  }

  goToPreviousSlide = () => {
    const {
      currentImageIndex,
    } = this.state

    const {
      imageLinks,
    } = this.props

    const newImageIndex = (currentImageIndex - 1 + imageLinks.length) % imageLinks.length

    this.setState({
      currentImageIndex: newImageIndex,
    })
  }

  goToNextSlide = () => {
    const {
      currentImageIndex,
    } = this.state

    const {
      imageLinks,
    } = this.props

    this.setState({
      currentImageIndex: (currentImageIndex + 1) % imageLinks.length,
    })
  }

  render() {
    const {
      currentImageIndex,
    } = this.state

    const {
      imageLinks,
      navigationColor,
      fromText,
      fromLink,
      moreInfo,
      link,
      className,
    } = this.props

    return (
      <div style={{ width: '100%', height: '100%' }} className={className}>
        <ImageContainer
          imageUrl={imageLinks[currentImageIndex]}
          ref={this.imageRef}
          key={currentImageIndex}
        />
        <ActionsContainer
          moreInfo={moreInfo}
          link={link}
        >
          <a
            style={{ cursor: 'pointer' }}
            href={link ? link.url : '#'}
            onClick={this.handleLinkClick}
            target={link ? '_blank' : ''}
          >
            {link ? link.text : moreInfo ? moreInfo.linkText : null}
          </a>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <NavigatingCircles>
              {imageLinks.map((imageLink) => (
                <NavigatingCircle
                  circleColor={navigationColor}
                  fillBackground={imageLink === imageLinks[currentImageIndex]}
                  key={imageLink}
                  onClick={() => this.setState({ currentImageIndex: imageLinks.indexOf(imageLink) })}
                />
              ))}
            </NavigatingCircles>
            {fromLink && fromText && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Link to={fromLink}>Back to {fromText}</Link>
            </div>}
          </div>
        </ActionsContainer>
        <Modal
          isOpen={this.state.modalOpen}
          onAfterOpen={this.onAfterOpenModal}
          onRequestClose={this.handleCloseModal}
          style={{
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            content: {
              position: 'unset',
              width: '75%',
              height: '75%',
            }
          }}
        >
          {moreInfo && moreInfo.content}
        </Modal>
      </div>
    )
  }
}

Gallery.propTypes = {
  moreInfo: PropTypes.shape({
    linkText: PropTypes.string,
    content: PropTypes.node,
  }),
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  autoplay: PropTypes.bool,
}

Gallery.defaultProps = {
  autoplay: true,
}

const GalleryPage = (props) => (
  <Layout className={props.className}>
    <MainSectionWrapperOverride>
      <Gallery {...props} />
    </MainSectionWrapperOverride>
  </Layout>
)

export default GalleryPage