import React from 'react'

import styled from 'styled-components'

import Gallery from '../../components/common/gallery';

const AureliaAbout = () => (
  <>
    <h1>
      aaaaaureliaaaaa
    </h1>
    <p>
      Laura is a Colombian fine artist under the pseudonym of aaaaaureliaaaaa. Her paintings are sutile represantations of our present.
    </p>
    <p>
      In collaboration with Laura we created a garment the following piece: an upcycled white jacket and movable art piece that speaks for itself.
    </p>
    <h1>
      Avant-garde fashion has many hues.
    </h1>
    <p>
      From practical to personal expression, this white garment,
      built from broken white pieces of scrap jeans and old white skirts,
      aims to be a white canvas for Laura Noguera to paint on.
    </p>
    <p>
      With the garment, we aspire to tilt the genre by colliding fine arts and sustanainable fashion.
    </p>
    <p>
      What would that look like?
    </p>
    <p>
      Take a look.
    </p>
  </>
)

const AureliaAboutStyled = styled(AureliaAbout)`
  & > p {
    margin-bottom: 0;
  }
`

const Aurelia = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 4, 5, 6].map((number) => (
        `https://s3.amazonaws.com/valentina-site/portfolio_images/aurelia/Aurelia${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Portfolio'
    fromLink='/portfolio'
    moreInfo={{
      linkText: 'How It Works',
      content: <AureliaAboutStyled />,
    }}
  />
)

export default Aurelia